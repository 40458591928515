import {timer} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {get, has} from 'lodash';
import {
  ActivatePatient, ActivatePatientAccount, ActivatePatientAccountFail, ActivatePatientAccountSuccess,
  ActivatePatientFail,
  ActivatePatientSuccess,
  ChangePassword,
  ChangePasswordFail,
  ChangePasswordSuccess,
  CheckSession,
  ConfirmEmail,
  ConfirmEmailFail,
  ConfirmEmailSuccess,
  ConfirmTOTPDevice,
  CreateTOTPDevice,
  DisablePreview,
  EnablePreview,
  FinishTutorial,
  FinishTutorialSuccess,
  GetPreferences,
  GetPreferencesSuccess,
  GetTOTPDevices,
  GetTOTPDevicesSuccess,
  Init,
  Loaded,
  Loading,
  LoginFail,
  LoginRedirect,
  LoginSuccess,
  LoginWithEmailAndPassword,
  LoginWithFacebook,
  LoginWithGoogle,
  Logout,
  LogoutSuccess,
  RegisterWithFacebook,
  RegisterWithGoogle,
  RegistrationFail,
  RegistrationSuccess,
  RegistrationUser,
  ReloadCurrentUser,
  ReloadCurrentUserSuccess,
  RemoveTOTPDevice,
  ResetPassword,
  ResetPasswordConfirm,
  ResetPasswordConfirmFail,
  ResetPasswordConfirmSuccess,
  ResetPasswordFail,
  ResetPasswordSuccess,
  SavePreferences,
  SendEmailConfirmation,
  SendEmailConfirmationFail,
  SendEmailConfirmationSuccess,
  SendFormSuccess,
  SendRegisterForm,
  SessionClosed,
  SessionSuccess,
  SetMailchimpTag,
  SetMailchimpTagSuccess,
  TwoFactorTokenValidate,
  TwoFactorTokenValidateFail,
  TwoFactorTokenValidateSuccess,
  UpdateUser,
  UpdateUserSuccess,
} from './auth.actions';
import {AuthStateModel, authUniqueStateId} from './auth.model';
import {AuthService} from '../../services/auth/auth.service';
import {BackendError, ErrorMessage, SuccessMessage} from '../../../messages/messages.actions';
import {DigitalMriRecordsService} from '../../../old-modules/mri-records/services/digital-mri-records.service';
import {RtPlatformService} from '../../../rt-platform/rt-platform.service';
import {Settings} from '../../../../conf/settings.base';
import {AUTH_REDIRECT_ROUTES, Preferences, SKIP_REDIRECT, TOTPDevice, TypeOfProfile, User} from '../../symbols';


@State<AuthStateModel>({
  name: authUniqueStateId,
  defaults: {
    initialized: false,
    loading: false,
    loaded: false,
    user: null,
    previewMode: false,
    preferences: null,
    hasAccount: false,
    sendEmailConfirmationLoading: false,
    sendEmailConfirmationLoaded: false,
    devices: [],
    unconfirmedDevice: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private settings: Settings,
    private auth: AuthService,
    private digitalMriRecordsService: DigitalMriRecordsService,
    private platform: RtPlatformService
  ) {}

  /**
   * Selectors
   */
  @Selector()
  static getInitialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static getPreviewMode(state: AuthStateModel): boolean {
    return state.previewMode;
  }

  @Selector()
  static getUserName(state: AuthStateModel): string {
    const user = state.user;
    if (user.first_name || user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }

    if (user.username) {
      return user.username;
    }

    if (user.email) {
      return user.email;
    }

    return 'User';
  }

  @Selector()
  static getUserFullName(state: AuthStateModel): any {
    const user = state.user;
    return {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
    };
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    if (state.user) {
      if (state.user.is_2fa_enabled) {
        return state.user.is_authenticated && state.user.is_authenticated_2fa;
      } else {
        return state.user.is_authenticated;
      }
    }

    return false;
  }

  @Selector()
  static isAnonymous(state: AuthStateModel): boolean {
    return state.user ? !state.user.is_authenticated : false;
  }

  @Selector()
  static hasAccount(state: AuthStateModel): boolean {
    return state.hasAccount;
  }

  @Selector()
  static isVerified(state: AuthStateModel): boolean {
    return state.user ? state.user.is_verified : false;
  }

  @Selector()
  static isLoading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static isLoaded(state: AuthStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static isInitialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static isPreviewMode(state: AuthStateModel): boolean {
    // To be ceratin, allow only when authenticated
    return state.user ? state.user.is_authenticated && state.previewMode : false;
  }

  @Selector()
  static isResearcher(state: AuthStateModel): boolean {
    if (!state.user) {
      return false;
    }
    return state.user.type_of_profile == TypeOfProfile.RESEARCHER;
  }

  @Selector()
  static isIndividual(state: AuthStateModel): boolean {
    if (!state.user) {
      return false;
    }
    return state.user.type_of_profile == TypeOfProfile.PRIVATE;
  }

  @Selector()
  static getPreferences(state: AuthStateModel): Preferences {
    return state.preferences;
  }

  @Selector()
  static emailConfirmationLoading(state: AuthStateModel): boolean {
    return state.sendEmailConfirmationLoading;
  }

  @Selector()
  static emailConfirmationLoaded(state: AuthStateModel): boolean {
    return state.sendEmailConfirmationLoaded;
  }

  @Selector()
  static getTOTPDevices(state: AuthStateModel): TOTPDevice[] {
    return state.devices;
  }

  @Selector()
  static getUnconfirmedTOTPDevice(state: AuthStateModel): TOTPDevice {
    return state.unconfirmedDevice;
  }

  @Selector()
  static mailchimpTags(state: AuthStateModel): string[] {
    if (state.user) {
      return state.user.mailchimp_tags;
    }

    return null;
  }

  @Selector()
  static getUserFirstName(state: AuthStateModel): any {
    const user = state.user;
    if (user) {
      return user.first_name || 'User';
    }
  }

  @Selector()
  static isResearchContributor(state: AuthStateModel): boolean {
    if (state.user) {
      return state.user.research_contributor;
    }

    return null;
  }

  @Selector()
  static isBrainkeyReportPaid(state: AuthStateModel): boolean {
    if (state.user) {
      return state.user.report_paid;
    }

    return null;
  }

  @Selector()
  static isSuperuser(state: AuthStateModel): boolean {
    if (state.user) {
      return state.user.is_superuser;
    }

    return null;
  }

  /**
   * Dispatch CheckSession on start
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({hasAccount: this.auth.hasAccount()});

    if (this.platform.isBrowser) {
      ctx.dispatch(new CheckSession());
    }

    const routerState$ = this.store.select(state => state.router.state);
    routerState$
      .pipe(
        switchMap(routeState =>
          this.store
            .select(AuthState.isAuthenticated)
            .pipe(filter(isAuthenticated => isAuthenticated && has(routeState, 'data.loginRoute')))
        )
      )
      .subscribe(() => ctx.dispatch(new Navigate([this.settings.LOGIN_REDIRECT_URL])));
  }

  @Action(Init)
  resetPasswordInit(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
  }

  /**
   * Commands
   */
  @Action(CheckSession)
  checkSession(ctx: StateContext<AuthStateModel>, {redirect}: CheckSession) {
    return this.auth.getCurrentUser().pipe(
      take(1),
      tap((user: User) => {
        if (user.is_authenticated) {
          ctx.dispatch(new SessionSuccess(user));
        } else {
          ctx.dispatch(new SessionClosed(user, redirect));
        }
      })
    );
  }

  @Action(LoginWithGoogle)
  loginWithGoogle(ctx: StateContext<AuthStateModel>, action: LoginWithGoogle) {
    this.auth
      .signInWithGoogle(action.token)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new LoginSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new LoginFail(err))
      );
  }

  @Action(LoginWithFacebook)
  loginWithFacebook(ctx: StateContext<AuthStateModel>, action: LoginWithFacebook) {
    this.auth
      .signInWithFacebook(action.token)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new LoginSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new LoginFail(err))
      );
  }

  @Action(LoginWithEmailAndPassword)
  loginWithEmailAndPassword(ctx: StateContext<AuthStateModel>, action: LoginWithEmailAndPassword) {
    this.auth
      .signInWithEmailAndPassword(action.data)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new LoginSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new LoginFail(err))
      );
  }

  @Action([LoginSuccess, SendFormSuccess])
  onLoginSuccess(ctx: StateContext<AuthStateModel>, action) {
    ctx.patchState({loading: false, loaded: true, user: action.user});

    if (action.redirect !== SKIP_REDIRECT) {
      let r = action.redirect || this.route.snapshot.queryParams['next'] || this.settings.LOGIN_REDIRECT_URL;

      const storageRedirectUrl = this.auth.getAuthRedirectUrlFromStorage();

      if (storageRedirectUrl) {
        r = storageRedirectUrl;
      }

      if (action.user && !action.user.is_verified) {
        this.auth.saveAuthRedirectUrlToStorage(action.redirect);
      }

      if (action.user && action.user.is_authenticated && action.user.is_2fa_enabled && !action.user.is_authenticated_2fa) {
        const routePath = Object.keys(AUTH_REDIRECT_ROUTES).find(key => AUTH_REDIRECT_ROUTES[key] === action.redirect);
        if (routePath) {
          r = `/auth/sign-in-2fa/${routePath}`;
        } else {
          r = '/auth/sign-in-2fa';
        }
      }

      ctx.dispatch(new Navigate([r], action.queryParams));
    }
  }

  @Action(LoginFail)
  loginFail({patchState, dispatch}: StateContext<AuthStateModel>, {err}: LoginFail) {
    patchState({loading: false, loaded: false});
    dispatch(new BackendError(err));
  }

  @Action(RegisterWithGoogle)
  registerWithGoogle(ctx: StateContext<AuthStateModel>, action: RegisterWithGoogle) {
    ctx.patchState({loading: true, loaded: false});
    // Requested for Google Analytics: https://github.com/BrainKey/brainkey-api/issues/1723
    const updatedQueryParams = {...action.queryParams, just_verified: true};
    this.auth
      .registerWithGoogle(action.token, this.auth.getInviteCode(), action.type_of_registration)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new RegistrationSuccess(user, action.redirect, updatedQueryParams)),
        err => ctx.dispatch(new RegistrationFail(err))
      );
  }

  @Action(RegisterWithFacebook)
  registerWithFacebook(ctx: StateContext<AuthStateModel>, action: RegisterWithFacebook) {
    ctx.patchState({loading: true, loaded: false});
    this.auth
      .registerWithFacebook(action.token, this.auth.getInviteCode(), action.type_of_registration)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new RegistrationSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new RegistrationFail(err))
      );
  }

  @Action(RegistrationUser)
  registration(ctx: StateContext<AuthStateModel>, action: RegistrationUser) {
    ctx.patchState({
      loading: true,
      loaded: false,
    });

    const data = action.sharingKey ? {...action.data, sharing_key: action.sharingKey} : {...action.data};

    const inviteCode = this.auth.getInviteCode();
    if (inviteCode) {
      data['invite'] = inviteCode;
    }

    this.auth
      .registration(data)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new RegistrationSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new RegistrationFail(err))
      );
  }

  @Action(RegistrationSuccess)
  onRegistrationSuccess(ctx: StateContext<AuthStateModel>, data: RegistrationSuccess) {
    ctx.patchState({
      user: data.user,
      loading: false,
      loaded: true,
    });

    if (data.redirect) {
      this.auth.saveAuthRedirectUrlToStorage(data.redirect);
    }

    if (data.redirect !== SKIP_REDIRECT) {
      const redirect = data.redirect || this.route.snapshot.queryParams['next'] || this.settings.LOGIN_REDIRECT_URL;
      ctx.dispatch(new Navigate([redirect], {...data.queryParams, first_login: true}));
    }
  }

  @Action(RegistrationFail)
  onRegistrationFail(ctx: StateContext<AuthStateModel>, {err}: RegistrationFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
    ctx.dispatch(new BackendError(err));
  }

  @Action(ReloadCurrentUser)
  reloadCurrentUser(ctx: StateContext<AuthStateModel>) {
    this.auth.getCurrentUser().subscribe(
      user => ctx.dispatch(new ReloadCurrentUserSuccess(user)),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(ReloadCurrentUserSuccess)
  reloadCurrentUserSuccess(ctx: StateContext<AuthStateModel>, {user}: ReloadCurrentUserSuccess) {
    ctx.patchState({user});
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: string) {
    ctx.patchState({
      loading: true,
      loaded: false,
    });
    this.auth.passwordReset(action['email']).subscribe(
      data => ctx.dispatch(new ResetPasswordSuccess(data)),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(ResetPasswordSuccess)
  resetPasswordSuccess(ctx: StateContext<AuthStateModel>, {detail}: ResetPasswordSuccess) {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    ctx.dispatch(new SuccessMessage(detail['detail']));
  }

  @Action(ResetPasswordFail)
  resetPasswordFail(ctx: StateContext<AuthStateModel>, {err}: ResetPasswordFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
    ctx.dispatch(new BackendError(err));
  }

  @Action(ResetPasswordConfirm)
  resetPasswordConfirm(ctx: StateContext<AuthStateModel>, {passwordUpdateData}: ResetPasswordConfirm): void {
    ctx.patchState({
      loading: true,
      loaded: false,
    });

    this.auth
      .resetPasswordConfirm(passwordUpdateData)
      .subscribe({
        next: data => ctx.dispatch(new ResetPasswordConfirmSuccess(data)),
        error: err => ctx.dispatch(new ResetPasswordConfirmFail(err))
      });
  }

  @Action(ResetPasswordConfirmSuccess)
  resetPasswordConfirmSuccess(ctx: StateContext<AuthStateModel>, {detail}: ResetPasswordConfirmSuccess) {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    ctx.dispatch([new SuccessMessage(detail['detail']), new Navigate([this.settings.LOGIN_URL])]);
  }

  @Action(ResetPasswordConfirmFail)
  resetPasswordConfirmFail(ctx: StateContext<AuthStateModel>, {err}: ResetPasswordFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });

    let hiddenError;
    const hiddenFields = ['uid', 'token'];
    for (const field of hiddenFields) {
      const errorLine = get(err, `error.${field}.0`, null);
      if (errorLine) {
        if (errorLine === 'Invalid value' && field === 'uid') {
          hiddenError = `${field}: The account does not exist or the link is invalid. Please, contact support if you have any questions.`;
        } else if (errorLine === 'Invalid value' && field === 'token') {
          hiddenError = `${field}: It seems that the link is outdated. Please, request a password reset once more.`;
        } else {
          hiddenError = `${field}: ${errorLine}`;
        }
        break;
      }
    }

    if (hiddenError) {
      ctx.dispatch([new BackendError(err), new ErrorMessage(hiddenError)]);
    } else {
      ctx.dispatch(new BackendError(err));
    }
  }

  @Action(ActivatePatientAccount)
  activatePatientAccount(ctx: StateContext<AuthStateModel>, {passwordUpdateData}: ActivatePatientAccount): void {
    ctx.patchState({
      loading: true,
      loaded: false,
    });

    this.auth
      .activatePatientAccount(passwordUpdateData)
      .subscribe({
        next: data => ctx.dispatch(new ActivatePatientAccountSuccess(data)),
        error: err => ctx.dispatch(new ActivatePatientAccountFail(err))
      });
  }

  @Action(ActivatePatientAccountSuccess)
  activatePatientAccountSuccess(ctx: StateContext<AuthStateModel>, {detail}: ActivatePatientAccountSuccess) {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    ctx.dispatch([new SuccessMessage(detail['detail']), new Navigate([this.settings.LOGIN_URL])]);
  }

  @Action(ActivatePatientAccountFail)
  activatePatientAccountFail(ctx: StateContext<AuthStateModel>, {err}: ActivatePatientAccountFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });

    let hiddenError;
    const hiddenFields = ['uid', 'token'];
    for (const field of hiddenFields) {
      const errorLine = get(err, `error.${field}.0`, null);
      if (errorLine) {
        if (errorLine === 'Invalid value' && field === 'uid') {
          hiddenError = `${field}: The account does not exist or the link is invalid. Please, contact support if you have any questions.`;
        } else if (errorLine === 'Invalid value' && field === 'token') {
          hiddenError = `${field}: It seems that the link is outdated. Please, request a new one from your clinic.`;
        } else {
          hiddenError = `${field}: ${errorLine}`;
        }
        break;
      }
    }

    if (hiddenError) {
      ctx.dispatch([new BackendError(err), new ErrorMessage(hiddenError)]);
    } else {
      ctx.dispatch(new BackendError(err));
    }
  }

  @Action(ConfirmEmail)
  onConfirmEmail(ctx: StateContext<AuthStateModel>, action: ConfirmEmail) {
    ctx.patchState({
      loading: true,
      loaded: false,
    });
    this.auth.confirmEmail(action.key).subscribe(
      data => ctx.dispatch(new ConfirmEmailSuccess(data)),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(ConfirmEmailSuccess)
  confirmEmailSuccess(ctx: StateContext<AuthStateModel>, {data}: ConfirmEmailSuccess) {
    const user = ctx.getState().user;
    ctx.patchState({
      loading: false,
      loaded: true,
      user: {...user, is_verified: true},
    });
    const isAuthenticated = user.is_authenticated;
    let redirectUrl = null;
    if (isAuthenticated) {
      redirectUrl = this.settings.ACCOUNT_EMAIL_CONFIRMATION_AUTHENTICATED_REDIRECT_URL || this.settings.LOGIN_REDIRECT_URL;
    } else {
      redirectUrl = this.settings.ACCOUNT_EMAIL_CONFIRMATION_ANONYMOUS_REDIRECT_URL || this.settings.LOGIN_URL;
    }

    const storageRedirectUrl = this.auth.getAuthRedirectUrlFromStorage();
    if (storageRedirectUrl) {
      redirectUrl = storageRedirectUrl;
    }

    ctx.dispatch(new Navigate([redirectUrl], {just_verified: true}));

    this.auth.clearAuthRedirectUrlInStorage();

    const message = data['detail'] === 'ok' ? 'Your email was successfully verified' : data['detail'];
    timer(1000).subscribe(() => ctx.dispatch(new SuccessMessage(message)));
  }

  @Action(ConfirmEmailFail)
  confirmEmailFail(ctx: StateContext<AuthStateModel>, {err}: ConfirmEmailFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
    ctx.dispatch(new BackendError(err));
  }

  @Action(SendRegisterForm)
  onSendRegisterForm(ctx: StateContext<AuthStateModel>, {formValue}: SendRegisterForm) {
    this.auth.sendQuest(formValue).subscribe(
      success => ctx.dispatch(new SendFormSuccess(success)),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    this.digitalMriRecordsService.clearRelatedStorage();
    return this.auth.logout().subscribe(() => ctx.dispatch(new LogoutSuccess()));
  }

  @Action(SessionSuccess)
  onSessionSuccess(ctx: StateContext<AuthStateModel>, action: SessionSuccess) {
    const hadAccount = ctx.getState().hasAccount;
    const hasAccount = hadAccount || action.user.is_authenticated;
    if (!hadAccount && hasAccount) {
      this.auth.hasAccountSave();
    }
    ctx.patchState({
      initialized: true,
      loading: false,
      user: action.user,
      hasAccount,
    });
  }

  @Action(SessionClosed)
  onSessionClosed(ctx: StateContext<AuthStateModel>, action: SessionClosed) {
    const user = ctx.getState().user;
    const wasAuthenticated = user && user.is_authenticated;
    ctx.patchState({
      initialized: true,
      loading: false,
      user: action.user,
    });
    if (wasAuthenticated && !action.user.is_authenticated && action.redirect) {
      ctx.dispatch(new LoginRedirect());
    }
  }

  @Action(LoginRedirect)
  onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate([this.settings.LOGIN_URL]));
  }

  @Action(LoginSuccess)
  setUserStateOnSuccess(ctx: StateContext<AuthStateModel>, event: LoginSuccess) {
    ctx.patchState({
      user: event.user,
      loading: false,
      loaded: true,
    });
  }

  @Action(LogoutSuccess)
  onLogoutSuccess(ctx: StateContext<AuthStateModel>) {
    const redirectUrl = this.settings.ACCOUNT_LOGOUT_REDIRECT_URL || '/';

    this.auth.clearAuthRedirectUrlInStorage();

    ctx.dispatch([new Navigate([redirectUrl]), new DisablePreview(), new CheckSession(false)]);
  }

  @Action([Loading])
  onLoading(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({loading: true});
  }

  @Action([Loaded])
  onLoaded(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({loading: false});
  }

  @Action(UpdateUser)
  onUpdateUser(ctx: StateContext<AuthStateModel>, action: UpdateUser) {
    const pk = ctx.getState().user.uid;
    if (pk) {
      ctx.patchState({loading: true, loaded: false});
      this.auth.updateUser(action.user, pk).subscribe(
        user => ctx.dispatch(new UpdateUserSuccess(user)),
        err => ctx.dispatch(new BackendError(err))
      );
    }
  }

  @Action(BackendError)
  onBackendError(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({loading: false});
  }

  @Action(UpdateUserSuccess)
  onUpdateUserSucces(ctx: StateContext<AuthStateModel>, action: UpdateUserSuccess) {
    ctx.patchState({user: action.user, loading: false, loaded: true});
  }

  @Action(EnablePreview)
  enablePreview(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({previewMode: true});
  }

  @Action(DisablePreview)
  disablePreview(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({previewMode: false});
  }

  @Action(SendEmailConfirmation)
  sendEmailConfirmation(ctx: StateContext<AuthStateModel>, {showMessage}: SendEmailConfirmation) {
    ctx.patchState({
      loading: true,
      loaded: false,
      sendEmailConfirmationLoading: true,
      sendEmailConfirmationLoaded: false,
    });
    this.auth.sendEmailConfirmation().subscribe(
      resp => ctx.dispatch(new SendEmailConfirmationSuccess(resp['message'], showMessage)),
      err => ctx.dispatch(new SendEmailConfirmationFail(err))
    );
  }

  @Action(SendEmailConfirmationSuccess)
  sendEmailConfirmationSuccess(ctx: StateContext<AuthStateModel>, {message, showMessage}: SendEmailConfirmationSuccess) {
    ctx.patchState({
      loading: false,
      loaded: true,
      sendEmailConfirmationLoading: false,
      sendEmailConfirmationLoaded: true,
    });

    if (showMessage) {
      ctx.dispatch(new SuccessMessage(message));
    }
  }

  @Action(SendEmailConfirmationFail)
  sendEmailConfirmationFail(ctx: StateContext<AuthStateModel>, {err}: SendEmailConfirmationFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
    ctx.dispatch(new BackendError(err));
  }

  @Action(ChangePassword)
  changePassword(ctx: StateContext<AuthStateModel>, {passwords: {old_password, new_password1, new_password2}}: ChangePassword) {
    ctx.patchState({
      loading: true,
      loaded: false,
    });
    this.auth.changePassword(old_password, new_password1, new_password2).subscribe(
      result => ctx.dispatch(new ChangePasswordSuccess(result.detail)),
      error => ctx.dispatch(new BackendError(error))
    );
  }

  @Action(ChangePasswordSuccess)
  changePasswordSuccess(ctx: StateContext<AuthStateModel>, {detail}: ChangePasswordSuccess) {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    ctx.dispatch(new SuccessMessage(detail));
  }

  @Action(ChangePasswordFail)
  changePasswordFail(ctx: StateContext<AuthStateModel>, {detail}: ChangePasswordFail) {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    ctx.dispatch(new ErrorMessage(detail));
  }

  @Action(GetPreferences)
  getPreferences(ctx: StateContext<AuthStateModel>, action: GetPreferences) {
    this.auth.getPreferences().subscribe(
      preferences => ctx.dispatch(new GetPreferencesSuccess(preferences)),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(GetPreferencesSuccess)
  getPreferencesSuccess(ctx: StateContext<AuthStateModel>, {preferences}: GetPreferencesSuccess) {
    ctx.patchState({preferences});
  }

  @Action(SavePreferences)
  savePreferences(ctx: StateContext<AuthStateModel>, action: SavePreferences) {
    this.auth.updatePreferences(action.preferences).subscribe(
      preferences => ctx.dispatch([new GetPreferencesSuccess(preferences), new SuccessMessage('Successfully updated your preferences')]),
      err => ctx.dispatch(new ErrorMessage('Unable to update your preferences'))
    );
  }

  @Action(GetTOTPDevices)
  getTOTPDevices(ctx: StateContext<AuthStateModel>, action: GetTOTPDevices) {
    if (this.platform.isBrowser) {
      this.auth.getTOTPDevices().subscribe(
        devices => ctx.dispatch(new GetTOTPDevicesSuccess(devices)),
        err => ctx.dispatch(new BackendError(err))
      );
    }
  }

  @Action(GetTOTPDevicesSuccess)
  getTOTPDevicesSuccess(ctx: StateContext<AuthStateModel>, {devices}: GetTOTPDevicesSuccess) {
    ctx.patchState({devices});
  }

  @Action(CreateTOTPDevice)
  createTOTPDevice(ctx: StateContext<AuthStateModel>, action: CreateTOTPDevice) {
    this.auth.createTOTPDevice().subscribe(
      device => ctx.patchState({unconfirmedDevice: device}),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(ConfirmTOTPDevice)
  confirmTOTPDevice(ctx: StateContext<AuthStateModel>, {device}: ConfirmTOTPDevice) {
    this.auth.confirmTOTPDevice(device).subscribe(
      result => {
        ctx.patchState({unconfirmedDevice: null});
        ctx.dispatch([new GetTOTPDevices(), new SuccessMessage(result.detail)]);
      },
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(RemoveTOTPDevice)
  removeTOTPDevice(ctx: StateContext<AuthStateModel>, {device}: RemoveTOTPDevice) {
    this.auth.removeTOTPDevice(device).subscribe(
      result => ctx.dispatch([new GetTOTPDevices(), new SuccessMessage(result.detail)]),
      err => ctx.dispatch(new BackendError(err))
    );
  }

  @Action(TwoFactorTokenValidate)
  twoFactorTokenValidate(ctx: StateContext<AuthStateModel>, action: TwoFactorTokenValidate) {
    this.auth
      .validate2FAToken(action.data.device, action.data.token, action.data.recovery)
      .pipe(switchMap(token => this.auth.getCurrentUser()))
      .subscribe(
        user => ctx.dispatch(new TwoFactorTokenValidateSuccess(user, action.redirect, action.queryParams)),
        err => ctx.dispatch(new TwoFactorTokenValidateFail(err))
      );
  }

  @Action(TwoFactorTokenValidateSuccess)
  twoFactorTokenValidateSuccess(ctx: StateContext<AuthStateModel>, action: TwoFactorTokenValidateSuccess) {
    ctx.patchState({loading: false, loaded: true, user: action.user});

    if (action.redirect !== SKIP_REDIRECT) {
      const r = action.redirect || this.route.snapshot.queryParams['next'] || this.settings.LOGIN_REDIRECT_URL;

      ctx.dispatch(new Navigate([r], action.queryParams));
    }
  }

  @Action(TwoFactorTokenValidateFail)
  twoFactorTokenValidateFail(ctx: StateContext<AuthStateModel>, {err}: TwoFactorTokenValidateFail) {
    ctx.patchState({loading: false, loaded: false});
    ctx.dispatch(new BackendError(err));
  }

  @Action(FinishTutorial)
  finishTutorial({getState, dispatch}: StateContext<AuthStateModel>) {
    const user = getState().user;

    if (user && !user.tutorial_complete) {
      this.auth.updateCurrentUser({tutorial_complete: true}).subscribe(
        () => dispatch(new FinishTutorialSuccess()),
        err => dispatch(new BackendError(err))
      );
    }
  }

  @Action(FinishTutorialSuccess)
  finishTutorialSuccess({patchState, getState}: StateContext<AuthStateModel>) {
    const user = getState().user;

    patchState({
      user: {...user, tutorial_complete: true},
    });
  }

  @Action(SetMailchimpTag)
  setMailchimpTag(ctx: StateContext<AuthStateModel>, {tag}: SetMailchimpTag) {
    this.auth.setMailchimpTag(tag).subscribe(
      result => ctx.dispatch(new SetMailchimpTagSuccess(result)),
      error => ctx.dispatch(new BackendError(error))
    );
  }

  @Action(SetMailchimpTagSuccess)
  setMailchimpTagSuccess(ctx: StateContext<AuthStateModel>, {user}: SetMailchimpTagSuccess) {
    const currentUser = ctx.getState().user;
    ctx.patchState({
      user: {...currentUser, ...user},
    });
  }

  @Action(ActivatePatient)
  activatePatient(ctx: StateContext<AuthStateModel>, {dateOfBirth, key}: ActivatePatient) {
    ctx.patchState({
      loading: true,
      loaded: false,
    });

    this.auth.activatePatient(dateOfBirth, key).subscribe(
      data => ctx.dispatch(new ActivatePatientSuccess(data)),
      err => ctx.dispatch(new ActivatePatientFail(err))
    );
  }

  @Action(ActivatePatientSuccess)
  activatePatientSuccess(ctx: StateContext<AuthStateModel>, {activationInfo}: ActivatePatientSuccess) {
    const user = ctx.getState().user;

    ctx.patchState({
      loading: false,
      loaded: true,
    });

    if (user && user.is_authenticated) {
      this.digitalMriRecordsService.clearRelatedStorage();
      this.auth.logout().subscribe(() => {
        ctx.dispatch([
          new Navigate([`/auth/password/reset/confirm/${activationInfo.uid}/${activationInfo.token}`]),
          new DisablePreview(),
          new CheckSession(false),
        ]);
      });
    } else {
      ctx.dispatch(new Navigate([`/auth/password/reset/confirm/${activationInfo.uid}/${activationInfo.token}`]));
    }
  }

  @Action(ActivatePatientFail)
  activatePatientFail(ctx: StateContext<AuthStateModel>, {err}: ActivatePatientFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });

    ctx.dispatch(new BackendError(err));
  }
}
