import {AvailablePermissionGroups} from '../permissions/permissions.symbols';

export interface DjangoAuthToken {
  key: string;
}

export enum TypeOfProfile {
  RESEARCHER = 1,
  PRIVATE = 2,
  OTHER = 3,
}

/**
 * Describes all possible types of an account.
 */
export enum TypeOfAccount {
  /** General account created by user with no additions. */
  FREE = 0,

  /** Not used anymore (previously it was indicating that the user has access to private scans (paid 199$)). */
  BASIC = 1, // .

  /** Premium account with full access to all features. Usually indicates that the user has relation to a clinic. */
  ENTERPRISE = 2,

  /** Not used anymore (previously it was indicating that the user has access to private scans (contributes to the research)). */
  BASIC_FREE = 3,
}

export interface UserGroup {
  id: number;

  name: AvailablePermissionGroups;
}

/**
 * Main user account data for all account types.
 */
export interface User {
  /** Unique user identifier. Changed from `id` to `uid` due to security reasons. */
  uid: string;

  username?: string;

  is_authenticated?: boolean;

  is_authenticated_2fa?: boolean;

  is_2fa_enabled?: boolean;

  is_staff?: boolean;

  is_verified?: boolean;

  is_charged?: boolean;

  email?: string;

  first_name?: string;

  last_name?: string;

  permissions?: string[];

  type_of_profile?: number;

  type_of_account?: number;

  additional_details?: string;

  explore_data?: any;

  assessment_data?: any;

  genetic_data?: string;

  center_employee?: boolean;

  groups?: UserGroup[];

  tutorial_complete?: boolean;

  mailchimp_tags?: string[];

  research_contributor?: boolean;

  is_physician?: boolean;

  date_joined?: string;

  avatar?: string;

  report_paid?: boolean;

  is_superuser?: boolean;

  is_clinic_admin?: boolean;

  /** Exist if user has relation to a clinic. Not present for Imaging Center. */
  clinic?: ClinicRelatedData;

  /** Exist if Imaging Center has relation to center with logo data. */
  center?: ClinicRelatedData;

  /**
   * Identifier of the latest published news that user read.
   */
  latest_news_read_version?: number;
}

export interface LoginPasswordData {
  email: string;

  password: string;
}

export interface RegisterData {
  username: string;

  email: string;

  password1: string;

  password2: string;

  first_name: string;

  last_name: string;

  sharing_key?: string;

  type_of_registration?: number;
}

export interface PasswordConfirm {
  old_password?: string;

  new_password1: string;

  new_password2: string;
}

export interface Quest {
  date_of_birth: string;

  sex: number;

  goal: number;

  have_scan?: boolean;

  need_get_scan?: boolean;

  have_dataset?: boolean;

  want_explore_open_dataset?: boolean;
}

export interface Preferences {
  email_marketing_updates?: boolean;

  email_new_features?: boolean;

  email_surveys?: boolean;

  email_research_opportunities?: boolean;

  contr_brain_research?: boolean;

  beta_program?: boolean;
}

export interface TOTPDevice {
  name: string;

  id: number;
}

export interface TwoFactorValidate {
  token: string;

  device?: number;

  recovery?: boolean;
}

/**
 * Information about Clinic or Center related to this account. Used for display logo.
 */
export interface ClinicRelatedData {
  name: string;

  logo1x: string;

  logo2x: string;

  logo3x: string;
}

export const SKIP_REDIRECT = '[SKIP REDIRECT]';

export enum AuthorizationType {
  HAS_MRI = 'has-mri',
  GET_RECORDS = 'get-records',
  GET_MRI = 'get-mri',
  PRINT_BRAIN = 'print-brain',
  UPLOAD_SCAN = 'upload-scan',
  GENETICS = 'genetics',
}

export const AUTH_REDIRECT_ROUTES = {
  'has-mri': '/dashboard/upload-scan',
  'get-records': '/dashboard/request-records',
  'get-mri': '/dashboard/mri-scheduling',
  'print-brain': '/dashboard/print-brain',
  'upload-scan': '/dashboard/upload-scan',
  genetics: '/dashboard/genetics',
};

export interface PatientActivationInfo {
  uid: string;

  token: string;
}

/**
 * Describes all possible types of registration.
 * Used for tracking the source of registration (Google Analytics).
 */
export const TYPE_OF_REGISTRATION = {
  general: 0, // default
  'has-mri': 1,
  'get-records': 2,
  'get-mri': 3,
  'upload-scan': 4,
};

/**
 * Describes necessary data for password reset.
 * Uid and token are usually provided in the link from the email.
 */
export interface PasswordUpdateData {
  /** User identifier. */
  uid: string;

  /** Token for password reset. */
  token: string;

  /** New password. */
  new_password1: string;

  /** New password confirmation. */
  new_password2: string;
}
