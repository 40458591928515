import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../../../universal/window/window.service';
import { DigitalRecordsFacility, DigitalRecordsRequest } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DigitalMriRecordsService {
  RECORDS_REQUEST_STEP_KEY = 'records_request_step';
  RECORDS_REQUEST_MAX_STEP_KEY = 'records_request_max_step';

  constructor(private http: HttpClient, @Inject(WINDOW) private window) { }

  getRecordsRequests() {
    return this.http.get<DigitalRecordsRequest[]>(`/api/mri-records-request/`);
  }

  getRecordRequest(requestId: number) {
    return this.http.get<DigitalRecordsRequest>(`/api/mri-records-request/${requestId}/`);
  }

  createRecordsRequest(updateInfo: any) {
    return this.http.post<DigitalRecordsRequest>(`/api/mri-records-request/`, updateInfo);
  }

  updateRecordsRequest(recordsRequestID: number, updateInfo: any) {
    return this.http.patch<DigitalRecordsRequest>(`/api/mri-records-request/${recordsRequestID}/`, updateInfo);
  }

  sendRecordsRequest(recordsRequestID: number, updateInfo: any) {
    return this.http.put<DigitalRecordsRequest>(`/api/mri-records-request/${recordsRequestID}/`, updateInfo);
  }

  getFacilities(recordsRequestID: number) {
    return this.http.get<DigitalRecordsFacility[]>(`/api/facility/${recordsRequestID}/`);
  }

  createFacility(recordsRequestID: number, updateInfo: any) {
    return this.http.post<DigitalRecordsFacility>(`/api/facility/${recordsRequestID}/`, updateInfo);
  }

  updateFacility(recordsRequestID: number, facilityID: number, updateInfo: any) {
    return this.http.patch<DigitalRecordsFacility>(`/api/facility/${recordsRequestID}/${facilityID}/`, updateInfo);
  }

  saveFacility(recordsRequestID: number, facilityID: number, updateInfo: any) {
    return this.http.put<DigitalRecordsFacility>(`/api/facility/${recordsRequestID}/${facilityID}/`, updateInfo);
  }

  deleteFacility(recordsRequestID: number, facilityID: number) {
    return this.http.delete<DigitalRecordsFacility>(`/api/facility/${recordsRequestID}/${facilityID}/`);
  }

  saveCurrentStep(step) {
    if (step && this.window.localStorage) {
      try {
        const localStorage = this.window.localStorage;
        localStorage.setItem(this.RECORDS_REQUEST_STEP_KEY, step);
      } catch (error) {

      }
    }
  }

  getCurrentStep(): number {
    if (this.window.localStorage) {
      try {
        const localStorage = this.window.localStorage;
        return localStorage.getItem(this.RECORDS_REQUEST_STEP_KEY)
      } catch (error) {

      }
    }
  }

  saveMaxStep(maxStep) {
    if (maxStep && this.window.localStorage) {
      try {
        const localStorage = this.window.localStorage;
        localStorage.setItem(this.RECORDS_REQUEST_MAX_STEP_KEY, maxStep);
      } catch (error) {

      }
    }
  }

  getMaxStep(): number {
    if (this.window.localStorage) {
      try {
        const localStorage = this.window.localStorage;
        return localStorage.getItem(this.RECORDS_REQUEST_MAX_STEP_KEY)
      } catch (error) {

      }
    }
  }


  clearRelatedStorage() {
    if (this.window.localStorage) {
      try {
        const localStorage = this.window.localStorage;
        localStorage.removeItem(this.RECORDS_REQUEST_STEP_KEY);
        localStorage.removeItem(this.RECORDS_REQUEST_MAX_STEP_KEY);
        return true;
      } catch (error) {

      }
    }
  }

}
