import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {WINDOW} from '../../../universal/window/window.service';
import {DjangoAuthToken, PasswordUpdateData, PatientActivationInfo, Preferences, Quest, TOTPDevice, User} from '../../symbols';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_ENDPOINT = '';
  HAS_ACCOUNT_COOKIE_KEY = 'has_account';
  INVITE_KEY = 'invite';
  AUTH_REDIRECT_KEY = 'auth_redirect';

  constructor(private http: HttpClient, private cookie: CookieService, @Inject(WINDOW) private window) { }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>('/api/v1/users/current/');
  }

  updateCurrentUser(data: Partial<User>): Observable<User> {
    const url = '/api/v1/users/current/';
    return this.http.patch<User>(url, data);
  }

  signInWithEmailAndPassword(formValue): Observable<DjangoAuthToken> {
    return this.http.post<DjangoAuthToken>('/api/auth/login/', formValue);
  }

  signInWithGoogle(token: string): Observable<any> {
    return this.http.post<any>('/api/fedauth/google/login', {token});
  }

  registerWithGoogle(token: string, inviteCode: string = null, typeOfRegistration: number = 0): Observable<any> {
    const params: any = {token};

    if (inviteCode) {
      params.invite = inviteCode;
    }

    params.type_of_registration = typeOfRegistration;

    return this.http.post<any>('/api/fedauth/google/register', params);
  }

  signInWithFacebook(token: string): Observable<any> {
    return this.http.post<any>('/api/fedauth/facebook/login', {token});
  }

  registerWithFacebook(token: string, inviteCode: string = null, typeOfRegistration: number = 0): Observable<any> {
    const params: any = {token};

    if (inviteCode) {
      params.invite = inviteCode;
    }

    params.type_of_registration = typeOfRegistration;

    return this.http.post<any>('/api/fedauth/facebook/register', params);
  }

  logout(): Observable<any> {
    return this.http.post('/api/auth/logout/', {});
  }

  registration(formValue): Observable<DjangoAuthToken> {
    return this.http.post<DjangoAuthToken>('/api/auth/registration/', formValue);
  }

  sendQuest(formValue): Observable<Quest> {
    return this.http.post<any>('/api/quest', formValue);
  }

  updateUser(user: Partial<User>, pk: User['uid']): Observable<User> {
    return this.http.patch<User>(`/api/v1/users/${pk}/`, user);
  }

  passwordReset(email: string): Observable<string> {
    return this.http.post<string>('/api/auth/password/reset/', {email});
  }

  /**
   * Confirm password reset after receiving email with token.
   * A new password is set via the method.
   */
  public resetPasswordConfirm(passwordUpdateData: PasswordUpdateData): Observable<string> {
    return this.http.post<string>('/api/auth/password/reset/confirm/', passwordUpdateData);
  }

  /**
   * Confirm account activation after receiving email with token.
   * Uses the same method as password reset.
   */
  public activatePatientAccount(passwordUpdateData: PasswordUpdateData): Observable<string> {
    return this.http.post<string>('/api/auth/password/reset/confirm/', passwordUpdateData);
  }

  confirmEmail(key: string ): Observable<string> {
    return this.http.post<string>(
      `${this.API_ENDPOINT}/api/auth/registration/verify-email/`,
      { key }
    );
  }

  sendEmailConfirmation(): Observable<any> {
    return this.http.post<any>(`${this.API_ENDPOINT}/api/rt-auth/send-email-confirmation/`, {});
  }

  changePassword(oldPassword, newPassword1, newPassword2): Observable<{detail: string}> {
    const requestBody = {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    };
    return this.http.post<{detail: string}>('/api/auth/password/change/', requestBody);
  }

  getPreferences(): Observable<Preferences> {
    return this.http.get<Preferences>('/api/preferences/');
  }

  updatePreferences(preferences: Preferences): Observable<Preferences> {
    return this.http.patch<Preferences>(`/api/preferences/`, preferences);
  }

  hasAccount(): boolean {
    return !!this.cookie.get(this.HAS_ACCOUNT_COOKIE_KEY);
  }

  hasAccountSave() {
    this.cookie.set(this.HAS_ACCOUNT_COOKIE_KEY, '1', 10000);
  }

  saveInviteCode(code) {
    if (code && this.window.localStorage) {
      try {
        this.window.localStorage.setItem(this.INVITE_KEY, code);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  getInviteCode(): string {
    if (this.window.localStorage) {
      try {
        return this.window.localStorage.getItem(this.INVITE_KEY);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  getTOTPDevices(): Observable<TOTPDevice[]> {
    return this.http.get<TOTPDevice[]>('/api/twofa/devices');
  }

  createTOTPDevice(): Observable<TOTPDevice> {
    return this.http.get<TOTPDevice>('/api/twofa/new');
  }

  confirmTOTPDevice(device: TOTPDevice): Observable<any> {
    return this.http.post<any>('/api/twofa/confirm', device);
  }

  removeTOTPDevice(device: TOTPDevice): Observable<any> {
    return this.http.post<any>('/api/twofa/remove', device);
  }

  validate2FAToken(device: number, token: string, recovery: boolean): Observable<any> {
    return this.http.post<any>('/api/twofa/login', {device, token, recovery});
  }

  setMailchimpTag(tag: string): Observable<User> {
    return this.http.post<any>('/api/add-mailchimp-tag/', {tag});
  }

  activatePatient(dateOfBirth: string, key: string): Observable<PatientActivationInfo> {
    return this.http.post<PatientActivationInfo>(`/api/mri-scheduling/active-patient/${key}/`, {date_of_birth: dateOfBirth});
  }

  saveAuthRedirectUrlToStorage(url) {
    if (url && this.window.localStorage) {
      try {
        this.window.localStorage.setItem(this.AUTH_REDIRECT_KEY, url);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  getAuthRedirectUrlFromStorage(): string {
    if (this.window.localStorage) {
      try {
        return this.window.localStorage.getItem(this.AUTH_REDIRECT_KEY);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  clearAuthRedirectUrlInStorage() {
    if (this.window.localStorage) {
      try {
        this.window.localStorage.removeItem(this.AUTH_REDIRECT_KEY);
        return true;
      } catch (error) {
        console.warn(error);
      }
    }
  }

}
